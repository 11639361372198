import {request} from "../../../util/Request";
import moment from "moment-timezone";
import Error from "../../../util/Error";
import {mapState} from "vuex";

const FORM_STATE = {
    id: null,
    comments: null,
    status: null,
    _method: 'post',
}

export default {
    data() {
        return {
            formFields: {...FORM_STATE},
            selectedLoad: null
        }
    },
    methods: {
        handleUnLoadConfirmClick(record) {
            this.formFields.id = record.id
            this.formFields.comments = null
            this.selectedLoad = record
        },
        async handleStatusUpdateSubmit() {
            if(this.global.pendingRequests > 0) {
                return false;
            }

            try {
                const response = await request({
                    url: '/reexport/loads/compound/supplier/unload/confirm',
                    method: 'post',
                    data: {
                        id: this.formFields.id,
                        comments: this.formFields.comments
                    },
                })

                this.itemUpdated()
                this.loadList()
                this.formFields = {...FORM_STATE}
                this.selectedLoad = null
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
    },
    computed: {
        ...mapState([
            'global',
        ]),
    }
}
